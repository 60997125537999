// @ts-ignore
import Validator from 'validatorjs'
import Vue from 'vue'
import { isAfter, isBefore, isValid, parse } from 'date-fns'

Validator.register(
  'ca-postalcode',
  (value) => {
    const regex = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i
    return !value || (typeof value === 'string' && regex.test(value))
  },
  'Please enter a valid postal code'
)

Validator.register(
  'ca-provincialcode',
  (value) => {
    const regex = /^(N[BLSTU]|[AMN]B|[BQ]C|ON|PE|SK)$/
    return !value || (typeof value === 'string' && regex.test(value))
  },
  'Please enter a valid provincial code'
)

Validator.register(
  'ca-countrycode',
  (value) => {
    return typeof value === 'string' && value === 'CA'
  },
  'Please enter a valid country code'
)

Validator.register(
  'bank-transit',
  (value) => {
    return /^\d+$/.test(value.toString()) && value.toString().length === 5
  },
  'Please enter a valid transit number'
)

Validator.register(
  'bank-institution',
  (value) => {
    return /^\d+$/.test(value.toString()) && value.toString().length === 3
  },
  'Please enter a valid institution number'
)

Validator.register(
  'bank-account',
  (value) => {
    return /^\d+$/.test(value.toString()) && value.toString().length > 2
  },
  'Please enter a valid account number'
)

Validator.register(
  'local-date',
  (value) => {
    if (typeof value !== 'string') {
      return false
    }

    const localFormat = Validator.getDefaultLang() === 'fr' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'
    const parsedDate = parse(value, localFormat, new Date())

    return (
      isValid(parsedDate) &&
      isAfter(parsedDate, new Date('1850-01-01')) &&
      isBefore(parsedDate, new Date())
    )
  },
  'Please enter a valid date'
)

Validator.register(
  'federal-tax-number',
  (value) => {
    if (typeof value !== 'string') {
      return false
    }

    return /^[0-9]{9}["RT"]{2}[0-9]{4}$/.test(value.replace(/ /g, ''))
  },
  'Please enter a valid TPS number'
)

Validator.register(
  'quebec-tax-number',
  (value) => {
    if (typeof value !== 'string') {
      return false
    }

    return /^[0-9]{10}["TQ"]{2}[0-9]{4}$/.test(value.replace(/ /g, ''))
  },
  'Please enter a valid TVQ number'
)

const enMessages = Validator.getMessages('en')
enMessages.required = 'This field is required.'
enMessages.required_if = 'This field is required.'
enMessages.required_if = 'This field is required.'
enMessages.date = 'Please enter a valid date.'
Validator.setMessages('en', enMessages)

const frMessages = Validator.getMessages('fr')
frMessages.required = 'Ce champ est obligatoire.'
frMessages.required_if = 'Ce champ est obligatoire.'
frMessages.email = "Cette adresse courriel n'est pas valide."
frMessages.min = {
  numeric: 'Ce champ doit être supérieur à :min.',
  string: 'Ce champ doit contenir plus de :min caractères.'
}
frMessages['ca-postalcode'] = 'Veuillez entrer un code postal valide.'
frMessages['ca-provincialcode'] = 'Veuillez choisir une province valide.'
frMessages['ca-countrycode'] = 'Veuillez choisir un pays valide.'
frMessages.date = 'Veuillez entrer une date valide.'
frMessages['bank-transit'] = 'Veuillez entrer un numéro de transit valide.'
frMessages['bank-institution'] = "Veuillez entrer un numéro d'institution valide."
frMessages['bank-account'] = 'Veuillez entrer un numéro de compte valide.'
frMessages['local-date'] = 'Veuillez entrer une date valide.'
frMessages['federal-tax-number'] = 'Veuillez entrer un numéro de TPS/TVH valide.'
frMessages['quebec-tax-number'] = 'Veuillez entrer un numéro de TVQ valide.'
Validator.setMessages('fr', frMessages)

export default Vue.extend({
  data () {
    return {
      errors: {}
    }
  },
  methods: {
    validate (inputs: object, rules: Validator.Rules) {
      this.resetErrors()

      const validation = new Validator(inputs, rules)
      const passes = validation.passes()

      if (!passes) {
        this.errors = validation.errors.all()
      }

      return passes
    },
    resetErrors () {
      this.errors = {}
    }
  },
  created () {
    Validator.useLang('fr')
  }
})
